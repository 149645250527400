<template>
  <div
    class="min-h-screen min-w-screen flex flex-col justify-between"
  >
    <div>
      <AppHeader />
      <main
        container="padded base"
      >
        <div class="flex">
          <SettingsMenu />
          <div class="mt-5 w-full">
            <slot />
          </div>
        </div>
      </main>
    </div>

    <LazyAppFooter />
  </div>
</template>
