<script setup lang="ts">
const links = [
  {
    leading: 'i-ph-chart-line',
    to: '/settings/organisation',
    label: 'Organisation',
  },
  {
    leading: 'i-ph-user',
    to: '/settings/users',
    label: 'Users',
  },
  {
    leading: 'i-ph-webhooks-logo',
    to: '/settings/webhooks',
    label: 'Webhooks',

  },
  {
    leading: 'i-ph-shield-warning',
    to: '/settings/api-keys',
    label: 'API Keys',
  },
  {
    leading: 'i-ph-sliders',
    to: '/settings/preferences',
    label: 'Preferences',
  },
  {
    leading: 'i-ph-grid-four',
    to: '/settings/integrations',
    label: 'Integrations',
  },
]
</script>

<template>
  <div class="py-5">
    <div class="ml-4 flex flex-row items-center gap-x-2">
      <AppIcon class="h-24px w-24px" />
      <p class="text-xl font-semibold">
        Settings
      </p>
    </div>
    <GNavLinkGroup :links="links" size="lg" class="border-none" />
  </div>
</template>
